export default [
  { name: '太陽帝國', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: -0 },
  { name: '王牌首席', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: -0 },
  { name: '王牌領域', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', mobileOffset: 0 },
  { name: '王牌團隊', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', mobileOffset: 0 },
  { name: '王牌結構', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', mobileOffset: 0 },
  { name: '王牌建築', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', mobileOffset: 0 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: -0 },
  { name: '基本資料', imgSrc: '', subTitle: '', section: 'house-info', svgSrc: '', mobileOffset: -0 },
]
