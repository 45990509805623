<template>
  <div>
    <div class="section1">
      <!--<img src="./s1/bg.png" :alt="`${info.caseName}_bg`" class="bg-img" v-if="isPC">
   <img src="./mo/1/bg.png" :alt="`${info.caseName}_bg`" class="bg-img" v-if="isMobile">
      <img src="./mo/1/bg_1_m.jpg" :alt="`${info.caseName}_bg`" class="bg-img" v-if="isMobile" data-aos="fade">
       <img src="./s1/title.png" :alt="`${info.caseName}_title`" class="title" data-aos="fade-up">
       <img src="./s1/p2.png" :alt="`${info.caseName}_p2`" class="p2" v-if="isPC" data-aos="zoom-in">
      <img src="./s1/bg_1.jpg" :alt="`${info.caseName}_bg`" class="bg-img" v-if="isPC" data-aos="fade">
      <img src="./s1/01.jpg" :alt="`${info.caseName}_bg`" class="img11" v-if="isPC"> -->
      <!-- <div class="img2" data-aos="fade">
        <img src="./s1/bg2.jpg" :alt="`${info.caseName}_bg`" />
      </div>
      <div class="img3" data-aos="fade">
        <img src="./s1/bg2.jpg" :alt="`${info.caseName}_bg`" />
      </div>
      <img
        src="./s1/top.svg"
        :alt="`${info.caseName}_logo`"
        class="logo"
        data-aos="fade-down"
        loading="lazy"
      /> -->
      <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.4 456.2"
        :alt="`${info.caseName}_logo`"
        class="logo op"
        loading="lazy" >
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-23.138" y1="350.0665" x2="358.862" y2="60.0665">
	<stop  offset="0" style="stop-color:#DCB33B"/>
	<stop  offset="0.4" style="stop-color:#D6A42B"/>
	<stop  offset="0.44" style="stop-color:#DAAD3D"/>
	<stop  offset="0.52" style="stop-color:#E4C66A"/>
	<stop  offset="0.62" style="stop-color:#F4EBAF"/>
	<stop  offset="0.78" style="stop-color:#CCAB46"/>
	<stop  offset="1" style="stop-color:#C39030"/>
</linearGradient>
<path class="st0" d="M59.4,253.3h94v4l-26.1,20.1l-2.5-1.2l6-19H87.1v23.8h30.3h11.4h4.4v54.8c0,8-9.8,13-9.8,13
	c-3.1,1.2-6.6-0.2-8-3.1c-4.7-10.4-17.8-10.7-17.8-10.7v-3.5h14.2c0.4,0,0.8,0,1.2,0c2.8-0.4,4.8-3,4.5-5.8v-40.6H87.1v82H70.8v-82
	H45.7v70.7H29.4v-74.8h3.5h12.9h25.1v-23.8H27.9c0.7,11.3-0.9,25.2-11.2,32.1L3,274.3c0,0,18.3-12.8,20.3-33.9h1.9
	c0,0,1.6,5.5,2.4,12.9h29.7C45.5,235.6,36,213.8,36,213.8l-0.5-2H2.2v-4h69.7v-12h20.3v12h60v4h-42.9l19.7,9.9
	c-15.8,14-36.6,25.6-36.6,25.6l-2.3,0.8l-0.3-4.8c7.2-9.6,13-20.2,17.2-31.5H92.2v0H71.9v0H40.6c8.2,7.7,17.5,14.1,27.7,19
	L59.4,253.3z M278.2,205.2l-0.9,1.5c0,0,7.9,8.2,10.2,18.1l9.2-6.3C292.2,212,285.7,207.3,278.2,205.2z M230.9,247.5v-4h-11.5h-10.2
	h-6.2v54h16.3V292h11.5v-4h-11.5v-40.5H230.9z M192.7,191.8h-13v175.7h16.3v-10.3h106v-4h-106V195.9h106v-4h-106H192.7z M305,367.5
	h16.3V191.8H305V367.5z M234.2,243.5v54h16.3v-54H234.2z M206.5,331c0,0,29.6-11.6,48.5-26.4l-0.5-2.1c0,0-36.9,8.3-53.9,6.8
	L206.5,331z M269.6,314.7c3.6,9,7.2,14.9,10,18.1c4.6,5.2,8.7,10.7,16.5,9.8s3.1-13.9,1.3-16.3c-1.8-2.4-2.1-3-2.4-10.4
	c-0.3-7.4,4.5-20.5,4.5-20.5l-1-2.4L288,315.9c-3.3-4.2-6-9.1-8.2-14.5c14-21.2,18.4-41.5,18.4-41.5l-16-9.8
	c-1.3,12.2-4.2,23.1-8.1,32.7c-3.8-17.1-4.8-35.5-4.8-49.6H299v-4h-29.7c0.1-11.5,0.8-19.1,0.8-19.1l-16.5-3
	c0,7.8,0.2,15.1,0.5,22.1h-52.6v4h52.8c1.6,29.4,5.8,51.6,10.5,67.7c-16.8,27.2-39.2,39.9-39.2,39.9l1.6,2.7
	C245.4,337.3,259.2,326.5,269.6,314.7z M227.4,80.8v4h102v-4H227.4z M322.6,98.9h-64.9c6.3-8,8.8-14,8.8-14H245
	c-3,22-18.7,38-18.7,38l1.3,1.6c11.9-6.7,20.6-14.6,26.7-21.5h4.7c-1.2,4.4-8.5,26.4-43.8,55.6l1.3,2.1c7.2-2.7,14.1-6.3,20.5-10.7
	c10.7-7.4,32.3-25.7,39.2-47h9.8c-1.3,5.3-10.2,36-53.5,69.2l1.3,2.1c0,0,19.9-8.6,30.7-16c10.7-7.4,31.9-33.9,38.7-55.3h2.3
	l-1.7,42.5c0,0-0.9,7.4-9.9,8.2l-20.5,0.4v2.5c3.9,0.9,7.7,2.1,11.4,3.6c0,0,5,2.2,5.9,10c0,0-0.1,5.4,10.9,2.1
	c0,0,19.2-5.6,20.8-29.7l0.3-39.6l-0.1,0V98.9z M254.3,65.1h44v-4h-44V37.8h44v-4h-44v-25h44v-4h-44h-1.4H238v66.9h16.3V65.1z
	 M317.9,4.8h-16.3v66.9h16.3V4.8z M194.6,14.3h16.2l-10.6,49l0,0l-0.1,0c5.6,10.1,9.8,20.8,12.6,32c4.5,18.4-3,23.5-7.5,23.5h-7.5
	l7,17.4c17.1,0,23.2-14.4,21.5-36.8c-1.5-20.2-19.1-34.3-22.4-36.8L233,14.3h0.3v-4h-38.7V0h-16.3v175.7h16.3V14.3z M87,54.2h67.4
	v-4H87V3.5H66.8v46.7H4.5v4h62.3C66.3,67.3,61.3,130.9,0,169.1l1,2.5C1,171.6,85.5,145.6,87,54.2z M91.9,73.4l-4.5,1.2
	c3.3,18.2,15.7,69,55.2,93.4l16.4-26.2C159,141.8,111.2,132.1,91.9,73.4z M99.6,138.4l-15.3-28.3l-15,28.4h0l0,0l0,0h0l15.3,28.3
	L99.6,138.4L99.6,138.4L99.6,138.4L99.6,138.4L99.6,138.4z"/>
<path class="st1" d="M94.7,449.6c0-0.9,0.2-1.8,0.6-2.6c0.4-0.8,0.9-1.5,1.5-2.1c0.6-0.6,1.3-1,2-1.4c0.7-0.3,1.5-0.5,2.2-0.5
	c0.8,0,1.6,0.2,2.3,0.5c0.7,0.3,1.4,0.8,2,1.4c0.6,0.6,1.1,1.3,1.4,2.1c0.4,0.8,0.5,1.7,0.5,2.6c0,0.8-0.2,1.7-0.5,2.4
	c-0.3,0.8-0.8,1.5-1.3,2.1c-0.6,0.6-1.2,1.1-2,1.5c-0.8,0.4-1.6,0.6-2.4,0.6c-0.9,0-1.7-0.2-2.5-0.5c-0.8-0.4-1.4-0.9-2-1.5
	c-0.6-0.6-1-1.3-1.3-2.1C94.9,451.3,94.7,450.5,94.7,449.6z M96.8,449.6c0,0.9,0.1,1.9,0.2,2.8c0.1,0.7,0.3,1.4,0.7,2
	c0.3,0.5,0.7,0.9,1.3,1.2c1.2,0.5,2.7,0.5,3.9,0c0.5-0.3,1-0.7,1.3-1.2c0.4-0.6,0.6-1.3,0.7-2c0.2-0.9,0.2-1.8,0.2-2.8
	c0-0.8-0.1-1.6-0.2-2.4c-0.1-0.7-0.3-1.4-0.6-2c-0.3-0.6-0.7-1.1-1.3-1.4c-1.3-0.7-2.9-0.7-4.2,0c-0.5,0.4-1,0.8-1.3,1.4
	c-0.3,0.6-0.5,1.3-0.6,2C96.8,448,96.8,448.8,96.8,449.6z"/>
<path class="st1" d="M108.8,455.7h2v-12.1h-2v-0.2h3.8l6.1,9.6l0,0v-9.4h-2v-0.2h4.3v0.2h-2v12.6h-0.4l-7.6-11.9l0,0v11.4h2v0.2
	h-4.2V455.7z"/>
<path class="st1" d="M122,455.7h2v-12.1h-2v-0.2h10.3v3.1H132c-0.1-0.4-0.3-0.8-0.5-1.2c-0.2-0.3-0.4-0.6-0.6-0.9
	c-0.3-0.2-0.6-0.4-0.9-0.5c-0.4-0.1-0.8-0.2-1.3-0.2h-3v5.4h1.2c0.3,0,0.7,0,1-0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.4-0.5,0.5-0.8
	c0.2-0.4,0.3-0.9,0.4-1.3h0.2v5.6h-0.2c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.3-0.4-0.5-0.6-0.6
	c-0.3-0.2-0.7-0.2-1.1-0.2h-1.5v6.4h3.4c0.6,0,1.1-0.1,1.6-0.4c0.4-0.3,0.8-0.6,1.1-1c0.3-0.4,0.5-0.8,0.6-1.2
	c0.1-0.3,0.2-0.7,0.2-1h0.2v3.9H122V455.7z"/>
<path class="st1" d="M138,455.7h1.6l4.9-12.7h0.4l4.9,12.7h1.6v0.2h-5.5v-0.2h2l-1.9-4.9h-4.2l-1.9,4.9h2v0.2H138V455.7z
	 M143.8,445.4l-2,5.1h4L143.8,445.4z"/>
<path class="st1" d="M151.7,455.7h2v-12.1h-2v-0.2h3.8l6.1,9.6l0,0v-9.4h-2v-0.2h4.3v0.2h-2v12.6h-0.4l-7.5-11.9l0,0v11.4h2v0.2
	h-4.3L151.7,455.7z"/>
<path class="st1" d="M164.8,455.7h2v-12.1h-2v-0.2h3.8h0.5h1h1c0.3,0,0.6,0,0.8,0.1c0.2,0,0.5,0.1,0.7,0.1c0.2,0,0.5,0.1,0.7,0.2
	c0.7,0.2,1.3,0.5,1.9,1c0.5,0.4,1,0.8,1.3,1.4c0.3,0.5,0.6,1.1,0.8,1.7c0.2,0.6,0.2,1.3,0.2,1.9c0,0.7-0.1,1.4-0.3,2.1
	c-0.2,0.6-0.4,1.1-0.8,1.6c-0.3,0.5-0.7,0.9-1.2,1.2c-0.4,0.3-0.9,0.6-1.4,0.8c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.1-0.8,0.2
	c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.7,0-1.1,0h-0.6h-0.6c-0.2,0-0.4,0-0.6,0h-3.6L164.8,455.7z M168.6,455.7c0.2,0,0.5,0,0.7,0
	c0.3,0,0.6,0,0.9,0s0.8,0,1.2-0.1c0.4,0,0.8-0.1,1.1-0.3c0.4-0.1,0.7-0.3,1-0.6c0.3-0.3,0.6-0.6,0.9-1c0.4-0.6,0.6-1.3,0.8-2
	c0.2-0.7,0.2-1.5,0.2-2.2c0-0.9-0.1-1.7-0.3-2.5c-0.1-0.7-0.5-1.4-0.9-2c-0.4-0.5-1-1-1.6-1.2c-0.8-0.3-1.7-0.5-2.5-0.4h-0.8h-0.7
	L168.6,455.7z"/>
<path class="st1" d="M185.1,449.6c0-0.9,0.2-1.8,0.6-2.6c0.4-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1,2-1.4c0.7-0.3,1.5-0.5,2.2-0.5
	c0.8,0,1.6,0.2,2.3,0.5c0.7,0.3,1.4,0.8,2,1.4c0.6,0.6,1.1,1.3,1.4,2.1c0.4,0.8,0.5,1.7,0.5,2.6c0,0.8-0.2,1.7-0.5,2.4
	c-0.3,0.8-0.8,1.5-1.3,2.1c-0.6,0.6-1.2,1.1-2,1.5c-0.8,0.4-1.6,0.6-2.4,0.6c-0.9,0-1.7-0.2-2.5-0.5c-0.8-0.4-1.4-0.9-2-1.5
	c-0.6-0.6-1-1.3-1.3-2.1C185.3,451.3,185.1,450.5,185.1,449.6z M187.2,449.6c0,0.9,0.1,1.9,0.2,2.8c0.1,0.7,0.3,1.4,0.7,2
	c0.3,0.5,0.8,0.9,1.3,1.2c1.2,0.5,2.7,0.5,3.9,0c0.5-0.3,1-0.7,1.3-1.2c0.4-0.6,0.6-1.3,0.7-2c0.2-0.9,0.2-1.8,0.2-2.8
	c0-0.8-0.1-1.6-0.2-2.4c-0.1-0.7-0.3-1.4-0.6-2c-0.3-0.6-0.7-1.1-1.3-1.4c-1.3-0.7-2.9-0.7-4.2,0c-0.5,0.4-1,0.8-1.3,1.4
	c-0.3,0.6-0.5,1.3-0.6,2C187.3,448,187.2,448.8,187.2,449.6z"/>
<path class="st1" d="M199.2,455.7h2v-12.1h-2v-0.2h3.8l6.1,9.6l0,0v-9.4h-2v-0.2h4.2v0.2h-2v12.6H209l-7.6-11.9l0,0v11.4h2v0.2h-4.2
	V455.7z"/>
<path class="st1" d="M212.4,455.7h2v-12.1h-2v-0.2h5.8v0.2h-2v12.1h3.2c0.9,0,1.8-0.3,2.5-1c0.7-0.8,1.1-1.7,1.3-2.7h0.2v3.9h-11
	L212.4,455.7z"/>
<path class="st1" d="M226,455.7h2v-5.1l-4-7h-1.6v-0.2h5.5v0.2H226l3.6,6.2l3.4-6.2h-1.8v-0.2h3.8v0.2h-1.7l-3.5,6.5v5.6h2v0.2H226
	V455.7z"/>
<linearGradient id="SVGID_00000091007540085999485990000010262684142881282475_" gradientUnits="userSpaceOnUse" x1="71.64" y1="361.8187" x2="281.64" y2="468.8187">
	<stop  offset="0" style="stop-color:#DCB33B"/>
	<stop  offset="0.4" style="stop-color:#D6A42B"/>
	<stop  offset="0.44" style="stop-color:#DAAD3D"/>
	<stop  offset="0.52" style="stop-color:#E4C66A"/>
	<stop  offset="0.62" style="stop-color:#F4EBAF"/>
	<stop  offset="0.78" style="stop-color:#CCAB46"/>
	<stop  offset="1" style="stop-color:#C39030"/>
</linearGradient>
<path style="fill:url(#SVGID_00000091007540085999485990000010262684142881282475_);" d="M95.6,396.4c0,0.9-0.1,3.7-0.1,4.8
	c0,0.4-0.1,0.7-0.4,0.7s-0.4-0.2-0.5-0.8v-0.5c-0.1-1.1-1.2-2.2-4.9-2.3l-5.3-0.1V415c0,3.8,0,7,0.2,8.8c0.1,1.1,0.4,2.1,1.6,2.2
	c0.7,0.1,1.4,0.2,2.1,0.2c0.5,0,0.7,0.1,0.7,0.3c0,0.2-0.3,0.4-0.8,0.4c-2.8,0-5.4-0.1-6.6-0.1c-1,0-3.6,0.1-5.3,0.1
	c-0.5,0-0.8-0.1-0.8-0.4c0-0.3,0.2-0.3,0.7-0.3c0.5,0,1-0.1,1.5-0.2c0.8-0.2,1.1-1.1,1.2-2.3c0.2-1.7,0.2-5,0.2-8.8l0-16.9l-6.2,0.2
	c-2.4,0.1-3.4,0.3-4,1.2c-0.3,0.4-0.6,0.9-0.7,1.4c-0.1,0.3-0.2,0.5-0.5,0.5s-0.4-0.2-0.4-0.6c0-0.7,0.8-4.4,0.9-4.7
	c0.1-0.5,0.2-0.8,0.5-0.8c0.6,0.2,1.2,0.4,1.8,0.5c1.2,0.1,2.8,0.2,4.2,0.2h16.5c1,0,1.9-0.1,2.9-0.2c0.6-0.1,1-0.2,1.1-0.2
	S95.6,395.8,95.6,396.4z M134.1,426.2c-0.7,0-1.4-0.1-2.1-0.2c-1.2-0.2-1.5-1.1-1.6-2.2c-0.2-1.8-0.2-5-0.2-8.8v-7.2
	c0-6.3,0-7.3,0.1-8.7c0.1-1.4,0.4-2.1,1.5-2.3c0.4-0.1,0.8-0.1,1.2-0.1c0.5,0,0.8-0.1,0.8-0.4c0-0.3-0.3-0.4-0.9-0.4
	c-1.6,0-4.1,0.1-5.3,0.1c-1.1,0-3.7-0.1-6-0.1c-0.6,0-0.9,0.1-0.9,0.4c0,0.3,0.2,0.4,0.7,0.4c0.5,0,1.1,0,1.6,0.1
	c1.4,0.3,1.7,1,1.8,2.3c0.1,1.3,0.1,2.5,0.1,8.7v0.9c0,0,0,0,0,0c0,0.1-0.1,0.2-0.3,0.2l-16,0c0,0,0,0,0,0c-0.1,0-0.2-0.2-0.2-0.3
	v-0.9c0-6.3,0-7.3,0.1-8.7c0.1-1.4,0.4-2.1,1.5-2.3c0.4-0.1,0.8-0.1,1.2-0.1c0.5,0,0.8-0.1,0.8-0.4c0-0.3-0.3-0.4-0.9-0.4
	c-1.6,0-4.2,0.1-5.3,0.1s-3.8-0.1-6-0.1c-0.6,0-0.9,0.1-0.9,0.4c0,0.3,0.2,0.4,0.7,0.4c0.5,0,1.1,0,1.6,0.1c1.4,0.3,1.7,1,1.8,2.3
	c0.1,1.3,0.1,2.5,0.1,8.7v7.2c0,3.8,0,7-0.2,8.8c-0.2,1.2-0.4,2.1-1.2,2.3c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0-0.7,0-0.7,0.3
	c0,0.3,0.3,0.4,0.8,0.4c1.7,0,4.3-0.1,5.3-0.1c1.2,0,3.8,0.1,6.5,0.1c0.5,0,0.8-0.2,0.8-0.4c0-0.2-0.2-0.3-0.7-0.3
	c-0.7,0-1.4-0.1-2.1-0.2c-1.2-0.2-1.5-1.1-1.6-2.2c-0.2-1.8-0.2-5-0.2-8.8v-3.6c0-0.2,0.1-0.2,0.2-0.2h16c0,0,0,0,0,0
	c0.1,0,0.2,0.1,0.2,0.2v3.6c0,3.8,0,7-0.2,8.8c-0.2,1.2-0.4,2.1-1.2,2.3c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0-0.7,0-0.7,0.3
	c0,0.3,0.3,0.4,0.9,0.4c1.6,0,4.2-0.1,5.2-0.1c1.2,0,3.8,0.1,6.5,0.1c0.5,0,0.8-0.2,0.8-0.4C134.8,426.3,134.6,426.2,134.1,426.2z
	 M160.4,420.7c-0.3,0-0.4,0.2-0.5,0.6c-0.3,1.7-0.8,2.5-1.9,3c-1.3,0.4-2.6,0.6-3.9,0.5c-4.2,0-4.9-0.6-5-3.5c0-1.2,0-5,0-6.4v-3.2
	c0-0.2,0.1-0.4,0.2-0.4c1,0,5.1,0,5.9,0.1c1.6,0.2,2.1,0.8,2.3,1.6c0.1,0.5,0.2,1,0.2,1.5c0,0,0,0.1,0,0.1c0,0.2,0.2,0.3,0.4,0.3
	c0.4,0,0.4-0.5,0.4-0.8c0-0.3,0.2-1.9,0.2-2.8c0.2-2.1,0.3-2.8,0.3-3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.4,0.2-0.7,0.5
	c-0.6,0.4-1.3,0.7-2,0.6c-1,0.1-5.8,0.1-6.7,0.1c-0.2,0-0.3-0.2-0.3-0.5v-10.4c0-0.3,0.1-0.4,0.3-0.4c0.8,0,5.2,0.1,5.9,0.2
	c2.1,0.2,2.5,0.7,2.8,1.5c0.2,0.5,0.3,1,0.2,1.5c0,0.4,0.1,0.6,0.4,0.6s0.4-0.2,0.4-0.5c0.1-0.5,0.2-2.3,0.2-2.7
	c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0-0.4-0.2-0.4c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-1,0.2-1.8,0.2c-0.8,0-9.1,0-10.4,0
	c-0.6,0-1.6-0.1-2.6-0.1c-1,0-2.2,0-3.4,0c-0.6,0-0.9,0.1-0.9,0.4c0,0.3,0.2,0.4,0.7,0.4c0.5,0,1.1,0,1.6,0.1c1.4,0.3,1.7,1,1.8,2.3
	c0.1,1.3,0.1,2.5,0.1,8.7l0,7.2c0,3.8,0,7-0.2,8.8c-0.2,1.2-0.4,2.1-1.2,2.3c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0-0.7,0-0.7,0.3
	c0,0.3,0.3,0.4,0.8,0.4c0.9,0,1.9-0.1,2.9-0.1s1.9,0,2.4,0c1.2,0,2.5,0.1,4.3,0.1c1.8,0,4.1,0.1,7.2,0.1c1.6,0,2,0,2.3-1.1
	c0.3-1.5,0.5-3,0.5-4.6C160.7,421,160.7,420.7,160.4,420.7z M207.6,426.5c0,0.3-0.4,0.4-1.9,0.4s-4.6,0-7.9-0.1
	c-0.8,0-1.2,0-1.2-0.3c0-0.3,0.2-0.3,0.6-0.4c0.3-0.1,0.5-0.5,0.4-0.9c0,0,0-0.1,0-0.1l-3.3-8.7c-0.1-0.2-0.2-0.3-0.4-0.3h-9.3
	c-0.2,0-0.3,0.1-0.4,0.3l-2.2,5.9c-0.3,0.8-0.6,1.6-0.6,2.5c0,0.7,0.4,1.3,1.7,1.3h0.6c0.5,0,0.6,0.1,0.6,0.3s-0.3,0.4-0.7,0.4
	c-1.3,0-3.1-0.1-4.4-0.1c-0.5,0-2.8,0.1-4.9,0.1c-0.5,0-0.8-0.1-0.8-0.4s0.2-0.3,0.5-0.3c0.3,0,1,0,1.3-0.1c1.9-0.2,2.7-1.7,3.6-3.7
	l10.5-25.9c0.5-1.2,0.8-1.7,1.2-1.7c0.4,0,0.6,0.4,1,1.3c1,2.3,7.7,19.1,10.4,25.3c1.6,3.7,2.8,4.3,3.7,4.5c0.6,0.1,1.2,0.2,1.8,0.2
	C207.4,426.2,207.6,426.2,207.6,426.5z M193.2,413.9l-3.8-10.2c0-0.2-0.1-0.4-0.2-0.4s-0.2,0.2-0.2,0.4l-3.8,10.1
	c0,0.2,0,0.3,0.2,0.3h7.7C193.2,414.1,193.2,414,193.2,413.9z M236.1,419.3c-0.3,0-0.4,0.2-0.5,0.8c-0.3,1-0.7,1.9-1.4,2.7
	c-1.6,1.9-3.9,2.3-7.6,2.3c-5.6,0-13.2-4.9-13.2-14.7c0-4,0.8-7.9,3.8-10.7c1.8-1.7,4.2-2.6,7.8-2.6c3.9,0,7.1,1.1,8.5,2.6
	c1,1,1.6,2.4,1.6,3.8c0,0.6,0,0.9,0.4,0.9s0.5-0.3,0.5-0.9c0.1-0.8,0-3,0.1-4.5s0.2-2,0.2-2.3s-0.2-0.5-0.6-0.5
	c-1.3-0.1-2.6-0.5-4.2-0.7c-2-0.3-4-0.4-6.1-0.4c-6.2,0-10.2,1.6-13.2,4.4c-0.2,0.2-0.4,0.4-0.6,0.6c-6.1,6.5-5.7,16.8,0.9,22.8
	c3.8,3.2,8.6,4.5,14.3,4.5c2.5,0.1,5.1-0.3,7.5-1c0.6-0.1,1-0.6,1.1-1.2c0.4-1.8,0.7-3.6,0.9-5.5
	C236.5,419.6,236.4,419.3,236.1,419.3z M261.9,420.7c-0.3,0-0.4,0.2-0.5,0.6c-0.3,1.7-0.8,2.5-1.9,3c-1.3,0.4-2.6,0.6-3.9,0.5
	c-4.2,0-4.9-0.6-5-3.5c0-1.2,0-5,0-6.4v-3.2c0-0.2,0-0.4,0.2-0.4c1,0,5.1,0,5.9,0.1c1.5,0.2,2.1,0.8,2.3,1.6c0.1,0.5,0.2,1,0.2,1.5
	c0,0.2,0,0.4,0.4,0.4s0.4-0.5,0.4-0.8c0-0.3,0.2-1.9,0.2-2.8c0.2-2.1,0.3-2.8,0.3-3c0-0.2-0.1-0.3-0.3-0.3s-0.4,0.2-0.7,0.5
	c-0.6,0.4-1.3,0.7-2,0.6c-1,0.1-5.8,0.1-6.7,0.1c-0.2,0-0.3-0.2-0.3-0.5v-10.4c0-0.3,0.1-0.4,0.3-0.4c0.8,0,5.2,0.1,5.9,0.2
	c2.1,0.2,2.5,0.7,2.8,1.5c0.2,0.5,0.2,1,0.2,1.5c0,0.4,0.1,0.6,0.4,0.6c0.3,0,0.4-0.2,0.4-0.5c0.1-0.5,0.2-2.3,0.2-2.7
	c0.1-0.9,0.2-1.8,0.3-2.7c0-0.2,0-0.4-0.2-0.4c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-1,0.2-1.8,0.2s-9.1,0-10.4,0c-0.6,0-1.6-0.1-2.6-0.1
	s-2.3,0-3.4,0c-0.6,0-0.9,0.1-0.9,0.4c0,0.3,0.2,0.4,0.7,0.4c0.5,0,1.1,0,1.6,0.1c1.4,0.3,1.7,1,1.8,2.3c0.1,1.3,0.1,2.5,0.1,8.7
	l0,7.2c0,3.8,0,7-0.2,8.8c-0.2,1.2-0.4,2.1-1.2,2.3c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0-0.7,0-0.7,0.3c0,0.3,0.3,0.4,0.8,0.4
	c0.9,0,1.9-0.1,2.9-0.1s1.9,0,2.4,0c1.2,0,2.5,0.1,4.3,0.1c1.8,0,4.1,0.1,7.2,0.1c1.6,0,2,0,2.2-1.1c0.3-1.5,0.5-3,0.5-4.6
	C262.3,421,262.2,420.7,261.9,420.7z"/>
</svg>
      <img
        src="./s1/top.gif"
        :alt="`${info.caseName}_logo`"
        class="logo screen op"
        loading="lazy"
      />

      
<svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.4 114"
:alt="`${info.caseName}_title`"
 class="title op"
 loading="lazy"
>
<rect x="0.3" y="78.2" class="st00" width="129.2" height="34.9"/>
<g>
	<path class="st11" d="M154,90.4c0.5-0.8,1-1.7,1.4-2.5h12.7v-9.3h-20.9v9.3h4c-1.2,3.1-3.1,6-5.6,8.5c-0.3-4.3-1.3-8.2-3.2-11.7
		c1.6-2.1,2.8-4.4,3.7-6.5l-3.7-1.6c-0.6,1.8-1.3,3.4-2.1,5c-1.2-1.5-2.5-2.9-4.1-4.2l-1.1,1.2c1.6,2,2.9,3.9,3.8,5.6
		c-1.4,2.3-2.9,4.4-4.7,6l1,1.4c1.9-1.2,3.6-2.7,5.1-4.4c0.4,1,0.7,2.1,0.9,3.3c-1.9,5.6-4.4,9.2-7.2,12.3l1,1.3
		c3-2.3,5.1-4.6,6.8-7.3c0,0.7,0,1.4,0,2.1c0,5.8-0.5,11.3-2.8,11.3c-0.9,0-2.4-0.5-3.6-1l-0.8,1.5c1.6,1.7,3.5,2.9,5.4,2.9
		c4.8,0,5.4-6.5,5.4-13.7c0-0.9,0-1.8-0.1-2.7l0.6,0.8c0.6-0.3,1.1-0.7,1.6-1.1v6.4h6v3.4c-2.3,0.2-4.9,0.3-7.4,0.3l0.9,3.7
		c4.2-0.6,9.1-1.7,13.2-3.1c0.4,0.9,0.8,1.7,1.2,2.5l2.7-1.7c-1.3-1.8-2.8-3.5-4.3-4.7l-1.2,1c0.3,0.4,0.6,1,0.8,1.4
		c-0.7,0.1-1.4,0.2-2.1,0.3v-3.1h6v-7h-6v-3.1h-3.7v3.1h-5.2c1.4-1.2,2.7-2.5,3.9-4h12.6c0,7.2-0.2,12.7-0.5,15.9
		c-0.2,2.7-1.2,2.7-5.6,1.7l-0.5,1.6c4.7,3,9.1,3.2,9.7-1.6c0.4-4,0.7-11,0.6-19.6L154,90.4L154,90.4z M162,80.6h2.5v5.3H162V80.6z
		 M156.4,80.6h2.4v5.3h-2.4V80.6z M157.5,98.3h2.7v3.2h-2.7V98.3z M151.2,98.3h2.7v3.2h-2.7V98.3z M153.3,85.9h-2.4v-5.3h2.4V85.9z"
		/>
	<path class="st11" d="M208.7,86.7v-2.1H194v-3c4.4-0.4,8.4-1,11.2-1.9l-3.7-3.4c-5.6,2.1-13.4,3.7-23.7,4.2l0.2,1.6
		c3.3,0.1,7.6,0,11.9-0.2v2.7h-14.6v2.1h10.9c-2.8,3.7-6.3,6.5-12.2,8.9l0.6,1.5c6.6-1.9,12.3-5.3,15.3-9.4v6.6h4.1v-6.8
		c3.9,5.4,10.1,8.4,14.1,9.2l1.9-4.3c-5.1-0.9-9.7-2.1-14.2-5.7L208.7,86.7L208.7,86.7z"/>
	<path class="st11" d="M199.8,95.3h-20.1v2h5.6c-0.8,8.6-4.9,12.4-10.8,15.2l0.7,1.5c7-1.9,13.3-6.6,14.4-16.7h5.6
		c-0.5,2.2-1.3,4.7-2.2,6.9l3.9,0.8c0.3-0.8,0.6-1.6,0.9-2.5h4.3c-0.4,2.2-0.8,3.9-1.2,5.1c-1,2.5-1.9,2.8-8,0.6l-0.8,1.5
		c6.4,5.5,10.7,4.6,12.3,0.3c0.7-1.9,1.5-4.9,2.2-9.4h-8.2C199,98.8,199.5,97,199.8,95.3z"/>
	<path class="st11" d="M228,100.4c-1.5,0.6-3.1,1.1-4.8,1.6v-9.7h4.2v-2h-4.2v-9.1h4.9v-2h-13.8v2h4.9v9.1h-4.5v2h4.5v10.8
		c-1.9,0.5-3.9,0.9-5.7,1.2l1.6,4.5c3.9-1.2,10.3-4.3,13.6-6.8L228,100.4z"/>
	<path class="st11" d="M247.1,102.6c0.1,5.3,0.1,6.5-1.2,6.6c-0.9,0.1-1.8,0.1-2.5,0c-1-0.1-1.2-0.6-1.2-1.6v-8.1h4.1V78.4h-17.2
		v21.2h3.7c-0.2,6.2-2.9,10.7-10.1,13l0.6,1.6c8.5-1.6,13.2-6.7,13.5-14.5h1.6v9.3c0,2.3,0.7,3.6,3.1,3.9c2.2,0.3,3.6,0.3,5.6,0
		c2.3-0.3,3.9-2,1.7-10.1h-1.7V102.6z M232.9,80.4h9.5v4.1h-9.5V80.4z M232.9,86.4h9.5v4.5h-9.5V86.4z M232.9,97.5v-4.6h9.5v4.6
		H232.9z"/>
	<path class="st11" d="M285.7,88.9c-1.2,2.1-2.5,4-3.9,5.7V82.4h-10.5c1.2-1.5,2.7-3.7,3.5-5.2l-4.8-0.9c-0.2,1.7-0.6,4.2-1,6.1h-8.2
		v17.5c-2,0.1-4.1,0.2-5.9,0.3l0.7,3.9c4.5-0.4,13.8-1.9,20.5-3.7c-7.4,6.2-16.1,9.2-22.5,10.9l0.4,1.6c6.8-1.1,15.7-3.9,23.7-9.7
		v4.9c0,2.4-0.5,3-7.1,1.9l-0.4,1.6c7.6,3.7,11.5,2.3,11.5-2.1v-9.7c2.7-2.5,5.1-5.3,7.2-8.7L285.7,88.9z M277.8,98.1
		c-3.6,0.5-8.2,1.1-12.8,1.5v-4.3h12.8V98.1z M277.8,93.4H265v-3.6h12.8V93.4z M277.8,87.7H265v-3.3h12.8V87.7z"/>
</g>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-115.2476" y1="-13.9487" x2="346.7524" y2="169.0513" gradientTransform="matrix(1 0 0 -1 0 116)">
	<stop  offset="0" style="stop-color:#DCB33B"/>
	<stop  offset="0.4041" style="stop-color:#D6A42B"/>
	<stop  offset="0.4427" style="stop-color:#DAAD3D"/>
	<stop  offset="0.5198" style="stop-color:#E4C66A"/>
	<stop  offset="0.6207" style="stop-color:#F4EBAF"/>
	<stop  offset="0.7795" style="stop-color:#CCAB46"/>
	<stop  offset="1" style="stop-color:#C39030"/>
</linearGradient>
<path class="st22" d="M5,16.3v15.8h23.2v-2.9H20V25h6v-3h-6v-3.9h5.1l1.6,1.4c1-0.9,1.9-1.9,2.8-3.1V32h23.6v-2.9h-8.5v-4.2h6.2V22
	h-6.2v-3.9h6.2v-2.8h-6.2v-3.5h7.9v-3h-9.1l2.8-1.3c-1.2-2.4-3.5-5.6-5.3-7.2l-2,1.1c0.7,1.8,1.8,4.9,2.4,7.4h-7
	c1.3-2.3,2.3-4.6,3-6.6L32,0.7c-1.3,6.2-3.1,11.1-5.9,15.9v-1.3h-6v-3.5h8.1v-3h-9.4L22,7.4c-1.3-2.4-3.6-5.7-5.4-7.3l-2,1.1
	c0.7,1.9,1.8,5,2.4,7.6H9.8c1.3-2.4,2.4-4.7,3.1-6.7L7.4,0.5C6,7.3,3.5,13.1,0,18.5l1.6,1.7C2.7,19.2,3.9,17.9,5,16.3z M35,11.8h4.5
	v3.5H35V11.8z M35,18.2h4.5V22H35V18.2z M35,24.9h4.5v4.2H35V24.9z M10.3,11.8h4.6v3.5h-4.6C10.3,15.3,10.3,11.8,10.3,11.8z
	 M10.3,18.2h4.6V22h-4.6C10.3,22,10.3,18.2,10.3,18.2z M10.3,25h4.6v4.4h-4.6C10.3,29.4,10.3,25,10.3,25z M47.3,37.5l-6-3.7l-1.6,1
	H8.5v3h6c2.7,4.8,6.2,8.3,10,11c-6,2.6-13.7,4.6-23.7,5.5l0.5,2.4c11.1-0.4,20.2-2.2,27.7-5.4c6.8,3.3,14.6,4.4,22.4,4.8l3-6.7
	c-6.8,0-13.1-0.2-18.9-1.5C40.2,45.2,44,41.8,47.3,37.5z M17.3,37.8h21.3c-2.2,3.1-5.1,6-9.1,8.4C24.9,44.4,20.8,41.8,17.3,37.8z
	 M89.6,54.6h5.8v-6.7h9.1v5.9h6V7.9h-21L89.6,54.6L89.6,54.6z M95.4,10.9h9.1v34h-9.1V10.9z M89.1,34.2l-4.8,3.9
	c-1.7-3.1-3.6-6.3-5.3-8.8v26.8h-6.2V31.7c-2.6,5.1-6.7,11.5-11.5,15.8l-1.8-1.8c4.5-5.9,9.1-14.3,12-24.7h-9.7v-3.1h10.9v-9
	c-3.8,0.6-7.3,1-10.4,1.2l-0.5-2.4c8.8-1.4,15.2-4,21-7.6l4.8,5.1c-2.7,1-5.7,1.8-8.7,2.5v10.2h9.3V21H79v4
	C82.4,27.4,86.7,31,89.1,34.2z M165,15.7c-2.7-4-5.7-7.9-8.5-11.5l1.7-1.9c4.1,2.3,7.9,5,11.8,8.8L165,15.7z M141.2,2.6
	c-1.6,4.4-3.7,9.1-6.5,13.6v40.3h-6.1V24.7c-2.4,2.9-5,5.6-7.7,7.8l-1.6-2c6.3-7.5,11.8-17.6,15.2-30L141.2,2.6z M172.3,37.9
	c2.4,10.9,1.9,19.8-5.7,18.2c-10.9-2.3-16.1-16.8-18-32.2l-11.3,1.6l-0.5-3.6c3.9-0.5,7.7-1.1,11.5-1.7c-0.5-5.6-0.7-11.8-0.9-18.3
	h6.3c0.2,6.5,0.5,12.4,0.8,17.3c5.9-0.9,11.9-1.9,18.1-2.9L173,20c-6.3,1.1-12.3,2.1-18.2,3c1.3,11.1,5.1,23.8,11.6,25.7
	c2.3,0.7,3-1.3,3-10.8H172.3z M217.7,39.5c4.2,4.2,9.6,7.5,16,9.7l-4.4,6.5c-7.9-2.4-19.4-13.6-21.8-28.7h-1
	c-1.6,2.8-3.9,5.3-6.6,7.6v13.3c4.3-0.7,8.2-1.4,11.5-2.3l0.8,2.3c-5.7,2.9-16.7,6.6-25.9,8.2l-1.8-6.7c3.2-0.2,6.3-0.4,9.3-0.7V39
	c-4.3,2.7-9,4.8-13.3,6.4l-1-2.2c8.7-4.3,14.7-9.6,19.5-16.2h-17.4v-3H203v-5.3h-16.8v-3H203v-5.3h-19.7v-3H203V1.1h6.2v6.2H229v3
	h-19.9v5.3h17.5v3h-17.5V24h22v3h-21c1.3,3.8,3.1,7.2,5.6,10.2c2.8-2.6,5.6-5.9,7.6-8.5l5.3,4.2C225.5,35.3,221.2,37.8,217.7,39.5z
	 M259.3,2.7c-1.5,4.8-3.4,9.1-5.6,13v40.4h-5.9V24.5c-2.6,3.3-5.3,5.9-7.7,7.6l-1.6-1.9c5-5.8,10.5-15.1,14.4-29.5L259.3,2.7z
	 M275.6,14.6v9.7h14.9v3h-14.9v10h15.1v3h-15.1V56h-6.1V14.6h-3c-2.7,4.9-6.2,9.4-9.9,12.7l-1.8-1.8c4.9-6.1,8.4-15.4,10-24.3
	l6.5,1.9c-0.8,2.8-1.9,5.7-3.2,8.5h24.3v3H275.6z"/>
<g>
	<path class="st11" d="M11,104.3c5.3-4.8,8.8-8.6,8.8-11.7c0-1.9-1.1-3-2.8-3c-1.4,0-2.6,1-3.6,2l-2.8-2.7c2.1-2.2,4-3.3,7-3.3
		c4.1,0,6.9,2.6,6.9,6.6c0,3.6-3.1,7.6-6.4,10.9c1-0.1,2.5-0.3,3.4-0.3h3.9v4.3H11V104.3z"/>
	<path class="st11" d="M28.3,104.3c5.3-4.8,8.8-8.6,8.8-11.7c0-1.9-1.1-3-2.8-3c-1.4,0-2.6,1-3.6,2L28,88.9c2.1-2.2,4-3.3,7-3.3
		c4.1,0,6.9,2.6,6.9,6.6c0,3.6-3.1,7.6-6.4,10.9c1-0.1,2.5-0.3,3.4-0.3h3.9v4.3H28.3V104.3z"/>
	<path class="st11" d="M45.8,97.2h8v3.5h-8V97.2z"/>
	<path class="st11" d="M55.9,104.6l2.3-3.2c1.3,1.2,2.8,2,4.4,2c2,0,3.2-0.8,3.2-2.4c0-1.8-1-2.9-5.4-2.9v-3.5c3.6,0,4.7-1.1,4.7-2.8
		c0-1.4-0.9-2.2-2.4-2.2c-1.4,0-2.5,0.7-3.8,1.8l-2.6-3.1c2-1.6,4.1-2.6,6.5-2.6c4.4,0,7.2,2,7.2,5.8c0,2.1-1.1,3.7-3.4,4.6v0.1
		c2.4,0.7,4.1,2.4,4.1,5.1c0,3.9-3.6,6.2-7.8,6.2C59.8,107.5,57.4,106.4,55.9,104.6z"/>
	<path class="st11" d="M73.2,104.6l2.3-3.2c1.2,1.1,2.7,2,4.3,2c2,0,3.3-1.1,3.3-3.3c0-2.1-1.3-3.3-3.1-3.3c-1.2,0-1.7,0.2-3,1
		l-2.2-1.4l0.5-10.4h11.9v4.2h-7.5l-0.3,3.6c0.8-0.3,1.4-0.4,2.2-0.4c3.6,0,6.6,2.1,6.6,6.7c0,4.7-3.6,7.5-7.6,7.5
		C77.2,107.5,74.9,106.3,73.2,104.6z"/>
	<path class="st11" d="M117.4,101h-6.8v8.8h-4.1V101h-6.7l0.4,2.1c-2.8,1.1-5.8,2.3-8.3,3.2l-1.4-4.2c0.9-0.2,2-0.5,3.1-0.9v-8h-2.6
		v-3.9h2.6v-6h3.7v6h2.2v3.9h-2.2v6.9l2.2-0.7l0.2,1.1v-3.5h6.8v-9h-6.1v-3.9h16.4v3.9h-6.2v9h6.8V101z M103.9,89
		c0.7,2,1.2,4.6,1.4,6.3l-3.4,0.9c-0.1-1.7-0.7-4.4-1.2-6.4L103.9,89z M111.5,95.2c0.5-1.8,1.1-4.5,1.3-6.5l3.8,0.8
		c-0.7,2.4-1.4,4.9-2,6.5L111.5,95.2z"/>
</g>

</svg>


      
      <img
        src="./s1/bottom.gif"
        :alt="`${info.caseName}_title`"
        class="title screen op"
        loading="lazy"
      />
      <div class="overlay"></div>
      <video loading="lazy" preload autoplay muted loop playsinline poster="" id="video" v-if="!isMobile">
        <source src="https://h35.banner.tw/ace/bg_video.mp4" type="video/mp4" />
      </video>
      <video preload autoplay muted loop playsinline poster="" id="video" v-if="isMobile">
        <source src="https://h35.banner.tw/ace/bg_750_1500.mp4" type="video/mp4" />
      </video>
      <!-- <img src="./s1/l.gif" data-aos="fade" :alt="`${info.caseName}_bg`" class="img4"> -->
      <!-- <img
        src="./s1/building.png"
        :alt="`${info.caseName}_p12`"
        class="p12"
        data-aos="fade-up"
      /> -->
      <!--  <img src="./mo/1/p2.png" :alt="`${info.caseName}_bg`" class="p2" v-if="isMobile" data-aos="zoom-in"> -->
      <div class="order-btn" v-scroll-to="{ element: `#contact` }">
        <span>預約鑑賞</span>
      </div>
      <!--<h1 class="title" data-aos="fade-up">
        不是王牌不出手<br />
        唯有贏家能擁有
      </h1>-->
      <!-- <img src="./s1/t2.png" alt="早鳥優惠驚喜價18-28坪" class="t2" data-aos="fade" data-aos-delay="800"> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/function.scss";
	.st0{fill:url(#SVGID_1_);}
	.st1{fill:#FFFFFF;}
	.st2{fill:url(#SVGID_00000065763873674909104240000010832613824314077591_);}

  .st3{fill:#C79F62;}
	.st4{fill:#FFFFFF;}
	.st5{fill:url(#SVGID_1_);}

  .st00{fill:#C79F62;}
	.st11{fill:#FFFFFF;}
	.st22{fill:url(#SVGID_1_);}
.section1 {
  background: #000;
  width: size(1920);
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
  overflow: hidden;
  position: relative;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.5);
  }

  video {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
}
.img11 {
  width: 100%;
  height: 100%;
  //min-height: 900px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
}
.img2 {
  width: 105vw;
  position: absolute;
  top: calc(50% - 45vw);
  left: -27vw;
  display: block;
  transform: rotate(-100deg);
  object-fit: cover;
  mix-blend-mode: screen;
  img {
    width: 100%;
    transform: rotate(180deg);
  }
}
.img3 {
  width: 142vw;
  position: absolute;
  top: calc(50% - 64vw);
  right: -34vw;
  display: block;
  transform: rotate(-100deg);
  object-fit: cover;
  mix-blend-mode: screen;
  img {
    width: 100%;
  }
}
.img4 {
  width: size(746);
  //min-height: 900px;
  position: absolute;
  top: calc(50% - 9.8vw);
  right: 20vw;
  display: block;
  object-fit: cover;
}
.bg-img {
  width: 100%;
  height: 100%;
  //min-height: 900px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
  transform: scaleY(1.3) rotate(10deg);
  //   animation: flip 5s 0s ease-in-out alternate infinite;
}
@keyframes flip {
  to {
    transform: scale(1.05, 1.2) rotate(4deg);
  }
}
.op{
  animation: op 1s .2s forwards;opacity:0;
}


@keyframes op {
  to {opacity:1;transform: translate(0,0);
  }
}
.logo {
  @include img_r_pc(329.4, 240, 792);
  top: calc(50% - 15vw);transform: translateY(-10%);
  z-index: 5;
}
.screen {mix-blend-mode: screen;
}

.p2 {
  @include img_r_pc(202, 113, 806);
  top: calc(50% - 21vw);
}

.p12 {
  @include img_l_pc(460, 125, 250);
  top: calc(50% + (125 - 1080 * 0.5) * 100vw / 1920);
}

.order-btn {
  @include img_r_pc(45, 803, 69);
  cursor: pointer;
  top: auto;
  bottom: 1.5vw;
  transition: all 0.5s;
  font-size: size(24);
  line-height: 1.2;
  font-weight: 400;
  color: #c39f6d;
  text-align: center;
  span {
    width: 100%;
    display: inline-block;
    padding: 0.8em 0.2em;
    color: #fff;
    z-index: 10;
    background: #c39f6d;
    border-radius: 0.5em;
    position: relative;
  }
  &::before {
    content: "";
    width: 0.25em;
    height: 0.25em;
    border-radius: 50%;
    background: currentColor;
    display: inline-block;
    transform: translateY(-1.5em);
  }
  &::after {
    content: "";
    width: 0.08em;
    height: 100%;
    background: currentColor;
    position: absolute;
    left: calc(50% - 0.04em);
    top: 1em;
    transform: translateY(-1.5em);
    display: inline-block;
    z-index: 0;
  }
  &:hover:before,
  &:hover:after {
    animation: btn 0.5s ease-in-out infinite alternate;
  }
}
@keyframes btn {
  to {
    transform: translateY(-2.5em);
  }
}

.title {
  @include img_r_pc(292.35, 726, 814);
  top: calc(50% + 11vw);
  font-size: size(45);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #685335;
  white-space: nowrap;
  z-index: 5;transform: translateY(10%);
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    min-height: auto;
    height: calc(100vh - 63px);
    min-height: sizem(750);
    max-height: sizem(750);
    background-size: cover;
    background-attachment: scroll;
    &::after,&::before{
      display: block;content:"";width: 100%;position: absolute;left: 0;
      height: 20vw;
      z-index: 2;
    }
    /*
    &::before{
      top:calc(50% - 81vw);
    background: linear-gradient(to bottom, rgba(0,0,0,1) 2%,rgba(0,0,0,0) 100%);
    }
    &::after{
      top:calc(50% + 61vw);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    }

    video {
     // left:0;
    //  transform: translateX(-50%);
    position: absolute;
    
    top: 50%;left: 0;
    transform: translateY(-50%);
    height:sizem(604);
    }
    */
  }
  .img2 {
    width: 109vw;
    top: -17vw;
    left: -15vw;
    img {
      transform: rotate(270deg);
    }
  }
  .img3 {
    width: 128vw;
    top: auto;
    bottom: -24vw;
    right: -14vw;
    img {
      transform: rotate(90deg);
    }
  }
  .img4 {
    width: 73.933333vw;
    top: calc(50% - 40.8vw);
    right: 13vw;
  }

  .bg-img {
    width: 100vw;
    height: 100%;
    //min-height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;

    &:nth-child(1) {
      position: relative;
    }
  }

  .logo {
    width: size-m(181.36);
    top: size-m(168);
    left: size-m(97);
  }

  .p2 {
    @include img_r_m(91, 143, 0);
    top: calc(50% + ((100 - 302) * 0.266666vw));
  }

  .p12 {
    @include img_c_m(150, 333);
    top: auto;
    bottom: sizem(-1);
    width: auto;
    height: calc(50% - 10vw);
  }

  .order-btn {
    display: none;
  }

  .title {
    width: size-m(160.96);
    left: size-m(108);
    top: size-m(435);
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'

export default {
  name: 'section1',

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
    }
  },

  methods: {},

  created() { },

  mounted() {
    $('body').on('click touchstart', function () {
      const videoElement = document.getElementById('video');
      if (videoElement.playing) {
      }
      else {
        videoElement.play();
      }
    });
  },

  computed: {},
}
</script>
