import { isMobile } from '../utils/index'
export default {
  isMobile,
  address: '新北市中和區景新街337號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.2607941892597!2d121.50979001500541!3d24.991252783992437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34680274835f1b4f%3A0xe726b38ca2ec6556!2zMjM15paw5YyX5biC5Lit5ZKM5Y2A5pmv5paw6KGXMzM36Jmf!5e0!3m2!1szh-TW!2stw!4v1613802554258!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/BmCc5wrL7WpW9z478',
  phone: '02-8668-7373',
  fbLink: 'https://www.facebook.com/ACEPTW86687373/',
  fbMessage: 'https://m.me/ACEPTW86687373',
  caseName: '太陽帝國',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '美聯開發股份有限公司'],
    ['使照號碼', '111中使字第00065號'],
    ['結構安全', '吳志強永安結構顧問'],
    ['外觀設計', '澳洲PTW建築師事務所'],
    ['建築設計', '九騰建築師事務所'],
    ['公設設計', '呈境設計'],
    ['燈光設計', '蘭克斯燈光照明'],
    ['園藝設計', '大漢景觀設計'],
    ['基地面積', '1128.34坪'],
    ['規劃坪數', '22-35坪'],
    ['企劃銷售', '得邦廣告'],
  ],

  gtmCode: ['KVNRLFH'], // 可放置多個
  // 得邦金鑰
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lep-78UAAAAAIDMeDQOBwK-OK3yNooejpZeNCsS', // recaptcha v3
  recaptcha_user_token: '6Lep-78UAAAAAIDMeDQOBwK-OK3yNooejpZeNCsS',
  order: {
    title: '立即預約',
    subTitle: ''
  }
}
